import {
  HiddenCost,
  NoObligation,
  PersonalisedService,
  ProtectPrivacy,
  TeamExperts,
  TimeStres,
} from "../../svgs/WhyDigital_Svgs";
import Internet from "../../images/ProductHeaderImages/CopiersPrinter.jpeg";
import { Product_Quote_Routes } from "../Routes";

export const Keybenifits = [
  {
    id: 1,
    src: HiddenCost,
    title: "Quality Assurance",
    paragraph: "Guarantee that all copies are high quality.  ",
  },
  {
    id: 2,
    src: TimeStres,
    title: "Funding",
    paragraph: "Assistance with financing the best equipment for your company.",
  },
  {
    id: 3,
    src: NoObligation,
    title: "Installation and Maintenance   ",
    paragraph: "Expert setup and ongoing maintenance.",
  },
  {
    id: 4,
    src: ProtectPrivacy,
    title: "Capacity",
    paragraph: "Produce the right volume for your business. ",
  },
  {
    id: 5,
    src: PersonalisedService,
    title: "Technology ",
    paragraph: "Utilise advanced technology such as image editing.",
  },
  {
    id: 6,
    src: TeamExperts,
    title: "Size and Convenience ",
    paragraph: "Implement equipment that fits perfectly in your workspace. ",
  },
];

export const HeaderData = {
  src: Internet,
  title: "Copiers & Printers",
  subtitle: "Choose the Right Copier for Your Business",
  paragraph: "Enhance your business with top-of-the-range equipment",
  to: Product_Quote_Routes.CopierandPrinter,
};

export const DescriptionContent = {
  title: "Why Do You Need a Copiers & Printers?",
  paragraph:
    "Boost business efficiency with the most effective copiers and printers on the market. Keep your staff happy, your customers satisfied, and your company running smoothly by getting the ideal office equipment for your business.",
  to: Product_Quote_Routes.CopierandPrinter,
  profitPoint1: "Reduced photocopying costs",
  profitPoint2: "Improved office organisation",
  profitPoint3: "Enhanced employee satisfaction and morale",
  profitPoint4: "Increased business efficiency",
};

export const FAQS = [
  {
    title: "Does every business need copiers and printers?",
    answer:
      " Copiers and printers are crucial to a business as long as they still use paper and hard copies.     ",
  },
  {
    title: "Do I need a black-and-white or colour copier?",
    answer:
      "This will depend on what kinds of documents you tend to photocopy. Work out what you’ll need to photocopy the most before making this decision.   ",
  },
  {
    title: "Can I lease this equipment instead of buying?",
    answer:
      "It is possible to lease copiers and printers. This can significantly reduce costs. However, it may be better to buy if you consistently produce large volumes of copies. ",
  },
  {
    title: "How much do printers and copiers cost?",
    answer:
      "Copiers and printers can be expensive. But it is worth investing in quality equipment if it’s crucial to your business.  ",
  },
  {
    title: "What are the benefits of a multi-function photocopier?",
    answer:
      "A multi-function photocopier incorporates several functions in a single machine. Because of this, they save space and money.",
  },
  {
    title: "Should I still get a printer and copier if I work from home?",
    answer:
      "There are smaller units available that are designed especially for home offices.  ",
  },
  {
    title: "How can Digital Comparison get me a good copier and printer?",
    answer:
      "We have a network of trusted suppliers at our fingertips. We contact them on your behalf, and they will provide you with an obligation-free quote.   ",
  },
  {
    title: "Which copier and printer supplier is the best?",
    answer:
      "Every provider that we work with is reliable and qualified. The right one for you comes down to your individual preferences.    ",
  },
];
